<template>
    <div style="padding:22px;">
        <el-row :gutter="12">
            <el-col :span="8">
                <el-card shadow="always">
                    <template #header>
                        <div class="card-header">
                            <h2>个人信息</h2>
                        </div>
                    </template>
                    <div style="text-align: center;margin-bottom:12px;">
                        <el-image style="width: 100px; height: 100px;border-radius: 50%;" :src="url" fit="cover"></el-image>
                    </div>
                    <div>
                        <el-form ref="formRef" :model="form" label-width="120px" label-position="left">
                            <el-form-item label="姓名">
                                <el-input v-model="form.name"></el-input>
                            </el-form-item>
                            <el-form-item label="身份证">
                                <el-input v-model="form.name"></el-input>
                            </el-form-item>
                            <el-form-item label="手机号">
                                <el-input v-model="form.name"></el-input>
                            </el-form-item>
                            <el-form-item label="生日">
                                <el-input v-model="form.name"></el-input>
                            </el-form-item>
                            <el-form-item label="性别">
                                <el-input v-model="form.name"></el-input>
                            </el-form-item>

                        </el-form>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="16">
                <el-card shadow="always">
                    <div>
                        <el-tabs v-model="activeName" class="demo-tabs">
                            <el-tab-pane label="User" name="first">User</el-tab-pane>
                            <el-tab-pane label="Config" name="second">Config</el-tab-pane>
                            <el-tab-pane label="Role" name="third">Role</el-tab-pane>
                            <el-tab-pane label="Task" name="fourth">Task</el-tab-pane>
                        </el-tabs>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    name: "usercenter",
    data() {
        return {
            url: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg9.51tietu.net%2Fpic%2F2019-090913%2F4wb5rwqe33h4wb5rwqe33h.jpg&refer=http%3A%2F%2Fimg9.51tietu.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1649302759&t=bbe4eae11c4159d6eeccaaeea88dede3",
            form: {
                name: "",
            },
            activeName: "first",
        };
    },
};
</script>
<style>
</style>